import './App.css';
import MyRoute from './MyRoute';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useEffect } from 'react';

function App() {
    useEffect(() => {
        // window.scrollTo(0, 0);
        // document.getElementById("root").scrollTo(0, 0);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    return (
        <HelmetProvider>
            <BrowserRouter>
                <MyRoute />
            </BrowserRouter>
        </HelmetProvider>
    );
}

export default App;
