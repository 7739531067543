import { Box, Button, ImageList, useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { metaData } from '../components/Datas';
import PageTitle from '../components/PageTitle';
import InfiniteScroll from 'react-infinite-scroll-component';
import CategoryCard from '../components/CategoryCard';
import CategoryCardSkeleton from '../components/CategoryCardSkeleton';
import { CustomAxios } from '../components/CustomAxios';
import ScrollToTop from '../components/ScrollToTop';

const Search = () => {
    const isLocation = useLocation();
    const searchParams = new URLSearchParams(isLocation.search);
    const searchKeyword = searchParams.get('search');

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [pageCate, setPageCate] = useState(100100);
    const [value, setValue] = useState(0);
    const [isitemData, setItemData] = useState([]);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const [isLoading, setLoading] = useState(false);
    const numCols = isMobile ? 2 : 4;
    const isHeader = metaData;
    const [isempty, setEmpty] = useState(false);

    const fetchMore = async (_searchKeyword) => {
        setLoading(true);

        await CustomAxios.post('/lists/search', {
            page: page,
            search: _searchKeyword,
        })
            .then((res) => {
                if (res.data.total === page) {
                    setHasMore(false);
                }
                setTimeout(async () => {
                    setItemData([...isitemData, ...res.data.datas]);
                    setPage(page + 1);
                }, 500);
            })
            .catch((err) => {});

        setLoading(false);
    };

    const fetchCategories = async (_searchKeyword) => {
        setLoading(true);

        await CustomAxios.post('/lists/search', {
            page: 1,
            search: _searchKeyword,
        })
            .then((res) => {
                if (res.data.total === page) {
                    setHasMore(false);
                }
                setTimeout(async () => {
                    setItemData(res.data.datas);
                    setPage(2);
                }, 500);
            })
            .catch((err) => {});

        setLoading(false);
    };

    const loadMoreData = useCallback(() => {
        if (!isLoading && page > 1) {
            fetchMore(searchKeyword);
        }
    }, [page]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setPage(1);
        fetchCategories(searchKeyword);
    }, [searchKeyword]);

    return (
        <Box>
            {isLoading ? null : <PageTitle data={isHeader} />}

            <Box style={{ padding: '10px', minHeight: '570px' }}>
                {page !== 1 && isitemData.length === 0 ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            marginTop: '150px',
                        }}
                    >
                        <div style={{ textAlign: 'center' }}>
                            <div>등록된 상품이 없습니다.</div>

                            <a href="https://tlk.kr">
                                <div
                                    style={{
                                        padding: '10px 10px',
                                        border: '1px solid #256fff',
                                        marginTop: '30px',
                                        backgroundColor: '#256fff',
                                        color: 'white',
                                        borderRadius: '7px',
                                    }}
                                >
                                    처음으로
                                </div>
                            </a>
                        </div>
                    </div>
                ) : (
                    <InfiniteScroll
                        dataLength={isitemData.length}
                        next={loadMoreData}
                        hasMore={hasMore}
                        loader={''}
                        endMessage={<p></p>}
                        initialScrollY={0}
                        scrollThreshold={1}
                    >
                        <ImageList variant="woven" cols={numCols} gap={15}>
                            {isitemData.length > 0
                                ? isitemData.map((item, index) => (
                                      <CategoryCard key={index} item={item} isLoading={isLoading} />
                                  ))
                                : Array.from({ length: 20 }).map((item, index) => (
                                      <CategoryCardSkeleton key={index} item={item} />
                                  ))}
                        </ImageList>
                    </InfiniteScroll>
                )}
            </Box>
        </Box>
    );
};

export default Search;
