import { Box, ImageList, useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import CategoryCard from '../components/CategoryCard';
import { metaData } from '../components/Datas';
import { CustomAxios } from '../components/CustomAxios';
import InfiniteScroll from 'react-infinite-scroll-component';
import CategoryCardSkeleton from '../components/CategoryCardSkeleton';
import PageTitle from '../components/PageTitle';
import { useLocation } from 'react-router-dom';
import CoupangBanner from '../components/CoupangBanner';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [pageCate, setPageCate] = useState(100100);
    const [value, setValue] = useState(0);
    const [isitemData, setItemData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const location = useLocation();
    const isMobile = useMediaQuery('(max-width: 600px)');

    const numCols = isMobile ? 2 : 4;
    const isHeader = metaData;

    const tabChangeHandle = useCallback((newValue) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setItemData([]);
        setPage(1);
        if (newValue === 0) {
            setPageCate(100100);
        } else if (newValue === 1) {
            setPageCate(200100);
        } else if (newValue === 2) {
            setPageCate(300100);
        } else if (newValue === 3) {
            setPageCate(400100);
        }
        setValue(newValue);
    });

    const TabPanel = ({ value, index, children }) => {
        return <div hidden={value !== index}>{value === index && <div>{children}</div>}</div>;
    };

    const fetchCategories = useCallback(
        async (_cate) => {
            console.log(hasMore);
            await CustomAxios.post('/lists', {
                page: page,
                category: _cate,
                search: '',
            })
                .then((res) => {
                    console.log(res.data.total, page * 20);
                    if (res.data.total === page * 20 || res.data.total < page * 20) {
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                    }
                    setItemData([...isitemData, ...res.data.datas]);
                    setPage(page + 1);

                    setLoading(false);
                })
                .catch((err) => {});
        },
        [pageCate, page]
    );

    const loadMoreData = useCallback(() => {
        if (!isLoading && page > 1 && hasMore) {
            setLoading(true);
            fetchCategories(pageCate).finally(() => {
                setLoading(false);
            });
        }
    }, [page]);

    useEffect(() => {
        fetchCategories(pageCate);
    }, [value]);

    return (
        <Box>
            <Helmet>
                <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
                />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css"
                />
                <link href="https://fonts.googleapis.com/css2?family=Ysabeau+SC&display=swap" rel="stylesheet" />
            </Helmet>

            <Box flexGrow={1}>
                {isLoading ? null : <PageTitle data={isHeader} />}
                {/* <div style={{ position: 'sticky', top: '0', zIndex: '999' }}>
                <Categorys tabChangeHandle={tabChangeHandle} />
            </div> */}

                <Box style={{ padding: '10px', minHeight: '570px' }}>
                    <TabPanel value={value} index={0}>
                        <InfiniteScroll
                            dataLength={isitemData.length}
                            next={loadMoreData}
                            hasMore={hasMore}
                            loader={''}
                            endMessage={<p></p>}
                            initialScrollY={0}
                            scrollThreshold={1}
                        >
                            <ImageList variant="woven" cols={numCols} gap={15}>
                                {isitemData.length > 0
                                    ? isitemData.map((item, index) => (
                                          <CategoryCard key={index} item={item} isLoading={isLoading} />
                                      ))
                                    : Array.from({ length: 20 }).map((item, index) => (
                                          <CategoryCardSkeleton key={index} item={item} />
                                      ))}
                            </ImageList>
                        </InfiniteScroll>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <InfiniteScroll
                            dataLength={isitemData.length}
                            next={loadMoreData}
                            hasMore={hasMore}
                            loader={''}
                            endMessage={<p></p>}
                            initialScrollY={0}
                            scrollThreshold={1}
                        >
                            <ImageList variant="woven" cols={numCols} gap={15}>
                                {isitemData.length > 0
                                    ? isitemData.map((item, index) => (
                                          <CategoryCard key={index} item={item} isLoading={isLoading} />
                                      ))
                                    : Array.from({ length: 20 }).map((item, index) => (
                                          <CategoryCardSkeleton key={index} item={item} />
                                      ))}
                            </ImageList>
                        </InfiniteScroll>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <InfiniteScroll
                            dataLength={isitemData.length}
                            next={loadMoreData}
                            hasMore={hasMore}
                            loader={''}
                            endMessage={<p></p>}
                            initialScrollY={0}
                            scrollThreshold={0.8}
                        >
                            <ImageList variant="woven" cols={numCols} gap={15}>
                                {isitemData.length > 0
                                    ? isitemData.map((item, index) => (
                                          <CategoryCard key={index} item={item} isLoading={isLoading} />
                                      ))
                                    : Array.from({ length: 20 }).map((item, index) => (
                                          <CategoryCardSkeleton key={index} item={item} />
                                      ))}
                            </ImageList>
                        </InfiniteScroll>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <InfiniteScroll
                            dataLength={isitemData.length}
                            next={loadMoreData}
                            hasMore={hasMore}
                            loader={''}
                            endMessage={<p></p>}
                            initialScrollY={0}
                            scrollThreshold={1}
                        >
                            <ImageList variant="woven" cols={numCols} gap={15}>
                                {isitemData.length > 0
                                    ? isitemData.map((item, index) => (
                                          <CategoryCard key={index} item={item} isLoading={isLoading} />
                                      ))
                                    : Array.from({ length: 20 }).map((item, index) => (
                                          <CategoryCardSkeleton key={index} item={item} />
                                      ))}
                            </ImageList>
                        </InfiniteScroll>
                    </TabPanel>
                </Box>
            </Box>
            <Box>
                <CoupangBanner />
            </Box>
        </Box>
    );
};

export default Home;
