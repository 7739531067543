import { ImageListItem, Skeleton } from '@mui/material';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const CategoryCard_noimage = ({ item, isLoading }) => {
    return (
        <div style={{ overflow: 'hidden', flexShrink: 0 }}>
            <Link to={`/content/${item.num}?cate=${item.cate1}`} style={{ display: 'block' }}>
                <ImageListItem key={item.img}>
                    <div style={{ padding: '35px' }}>
                        <Skeleton variant="rectangular" width={300} height={200} />
                    </div>

                    <div style={{ lineHeight: '1.3' }}>
                        <div>
                            <h2
                                style={{
                                    fontWeight: 'bold',
                                    fontFamily: 'Pretendard',
                                    marginBottom: '5px',
                                    marginTop: '8px',
                                    fontSize: '1rem',
                                    letterSpacing: '0.5px',
                                }}
                            >
                                {item.title}
                            </h2>
                            <h3
                                style={{
                                    fontFamily: 'Pretendard',
                                    marginBottom: '30px',
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                    letterSpacing: '0.5px',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                dangerouslySetInnerHTML={{ __html: item.descript }}
                            ></h3>
                        </div>
                    </div>
                </ImageListItem>
            </Link>
        </div>
    );
};

export default CategoryCard_noimage;
