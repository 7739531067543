export const siteName = 'ItemChoice';

export const itemData = [
    {
        category: 0,
        items: [
            {
                img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
                title: 'Bed',
                author: 'swabdesign',
                descript: '0qui consequuntur ducimus possimus quisquam amet similique suscipit ',
                seq: 1,
            },
            {
                img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
                title: 'Sink',
                author: 'Charles Deluvio',
                descript: '0삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 2,
            },
            {
                img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
                title: 'Kitchen',
                author: 'Christian Mackie',
                descript: '0삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 3,
            },
            {
                img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
                title: 'Blinds',
                author: 'Darren Richardson',
                descript: '0삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 4,
            },
        ],
    },
    {
        category: 1,
        items: [
            {
                img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfFheRojh54US-gZhRlS80d1VC8ghFviv2mNxVfmPlpDvA2kZqqywIw8s1bIo1P_jz2kI&usqp=CAU',
                title: 'Bed',
                author: 'swabdesign',
                descript: '1삶의 질을 확 올려줄 로봇청소기!<br />어떻게 골라야 할까요.',
                seq: 11,
            },
            {
                img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfFheRojh54US-gZhRlS80d1VC8ghFviv2mNxVfmPlpDvA2kZqqywIw8s1bIo1P_jz2kI&usqp=CAU',
                title: 'Sink',
                author: 'Charles Deluvio',
                descript: '1삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 12,
            },
            {
                img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
                title: 'Kitchen',
                author: 'Christian Mackie',
                descript: '1삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 13,
            },
            {
                img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
                title: 'Blinds',
                author: 'Darren Richardson',
                descript: '1삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 14,
            },
        ],
    },
    {
        category: 2,
        items: [
            {
                img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfFheRojh54US-gZhRlS80d1VC8ghFviv2mNxVfmPlpDvA2kZqqywIw8s1bIo1P_jz2kI&usqp=CAU',
                title: 'Bed',
                author: 'swabdesign',
                descript: '2삶의 질을 확 올려줄 로봇청소기!<br />어떻게 골라야 할까요.',
                seq: 21,
            },
            {
                img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfFheRojh54US-gZhRlS80d1VC8ghFviv2mNxVfmPlpDvA2kZqqywIw8s1bIo1P_jz2kI&usqp=CAU',
                title: 'Sink',
                author: 'Charles Deluvio',
                descript: '2삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 22,
            },
            {
                img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
                title: 'Kitchen',
                author: 'Christian Mackie',
                descript: '2삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 23,
            },
            {
                img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
                title: 'Blinds',
                author: 'Darren Richardson',
                descript: '2삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 24,
            },
        ],
    },
    {
        category: 3,
        items: [
            {
                img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfFheRojh54US-gZhRlS80d1VC8ghFviv2mNxVfmPlpDvA2kZqqywIw8s1bIo1P_jz2kI&usqp=CAU',
                title: 'Bed',
                author: 'swabdesign',
                descript: '3삶의 질을 확 올려줄 로봇청소기!<br />어떻게 골라야 할까요.',
                seq: 31,
            },
            {
                img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfFheRojh54US-gZhRlS80d1VC8ghFviv2mNxVfmPlpDvA2kZqqywIw8s1bIo1P_jz2kI&usqp=CAU',
                title: 'Sink',
                author: 'Charles Deluvio',
                descript: '3삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 32,
            },
            {
                img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
                title: 'Kitchen',
                author: 'Christian Mackie',
                descript: '3삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 33,
            },
            {
                img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
                title: 'Blinds',
                author: 'Darren Richardson',
                descript: '3삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 34,
            },
        ],
    },
    {
        category: 4,
        items: [
            {
                img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfFheRojh54US-gZhRlS80d1VC8ghFviv2mNxVfmPlpDvA2kZqqywIw8s1bIo1P_jz2kI&usqp=CAU',
                title: 'Bed',
                author: 'swabdesign',
                descript: '4삶의 질을 확 올려줄 로봇청소기!<br />어떻게 골라야 할까요.',
                seq: 41,
            },
            {
                img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfFheRojh54US-gZhRlS80d1VC8ghFviv2mNxVfmPlpDvA2kZqqywIw8s1bIo1P_jz2kI&usqp=CAU',
                title: 'Sink',
                author: 'Charles Deluvio',
                descript: '4삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 42,
            },
            {
                img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
                title: 'Kitchen',
                author: 'Christian Mackie',
                descript: '4삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 43,
            },
            {
                img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
                title: 'Blinds',
                author: 'Darren Richardson',
                descript: '4삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 44,
            },
        ],
    },
    {
        category: 5,
        items: [
            {
                img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfFheRojh54US-gZhRlS80d1VC8ghFviv2mNxVfmPlpDvA2kZqqywIw8s1bIo1P_jz2kI&usqp=CAU',
                title: 'Bed',
                author: 'swabdesign',
                descript: '5삶의 질을 확 올려줄 로봇청소기!<br />어떻게 골라야 할까요.',
                seq: 51,
            },
            {
                img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfFheRojh54US-gZhRlS80d1VC8ghFviv2mNxVfmPlpDvA2kZqqywIw8s1bIo1P_jz2kI&usqp=CAU',
                title: 'Sink',
                author: 'Charles Deluvio',
                descript: '5삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 52,
            },
            {
                img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
                title: 'Kitchen',
                author: 'Christian Mackie',
                descript: '5삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 53,
            },
            {
                img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
                title: 'Blinds',
                author: 'Darren Richardson',
                descript: '5삶의 질을 확 올려줄 로봇청소기! 어떻게 골라야 할까요.',
                seq: 54,
            },
        ],
    },
];

export const itemCategoryData = [
    { title: '생활가전', num: '100100', img: '100100.png' },
    { title: '주방가전', num: '200100', img: '200100.png' },
    { title: '계절가전', num: '300100', img: '300100.png' },
    { title: '디지털IT', num: '400100', img: '400100.png' },
];

export const metaData = [
    {
        title: 'ItemChoice : 상품 추천 가이드',
        img: '/00130-1883207688.png',
        keyword:
            'ItemChoice, 아이템초이스, 탁월한 상품 추천, 다양한 제품 정보, 리뷰 추천, 최신 트렌드, 고품질 상품, 스마트한 쇼핑 경험, 현명한 소비자, 가장 적합한 상품 선별',
        descript:
            'ItemChoice(아이템초이스)은 탁월한 상품 추천을 위한 플랫폼입니다. 다양한 제품에 대한 정보와 리뷰를 바탕으로 추천을 통해 소비자들에게 가장 적합한 상품을 선별합니다. ItemChoice(아이템초이스)은 최신 트렌드와 고품질 및 가성비 상품에 초점을 맞추며, 사용자들이 스마트하고 현명한 쇼핑 경험을 할 수 있도록 지원합니다.',
        url: `https://tlk.kr`,
    },
];
