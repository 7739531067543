import { useMediaQuery } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const CoupangBanner3 = () => {
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width: 600px)');
    const bannerRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://ads-partners.coupang.com/g.js';
        script.async = true;
        script.onload = () => {
            if (window.PartnersCoupang) {
                new window.PartnersCoupang.G({
                    id: 780717,
                    template: 'banner',
                    trackingCode: 'AF2113084',
                    width: '300',
                    height: '250',
                    appendTo: bannerRef.current,
                });
            }
        };
        if (bannerRef.current) {
            bannerRef.current.appendChild(script);
        }
    }, [isMobile, location.pathname]);

    return <div ref={bannerRef} style={{ top: '0', left: '0', zIndex: '1000' }}></div>;
};

export default CoupangBanner3;
