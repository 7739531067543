import { useMediaQuery } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const CoupangBanner = () => {
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width: 600px)');
    const bannerRef = useRef(null);

    useEffect(() => {
        if (!isMobile && location.pathname !== '/banner') {
            const script = document.createElement('script');
            script.src = 'https://ads-partners.coupang.com/g.js';
            script.async = true;
            script.onload = () => {
                if (window.PartnersCoupang) {
                    new window.PartnersCoupang.G({
                        id: 779754,
                        template: 'banner',
                        trackingCode: 'AF2113084',
                        width: '160',
                        height: '600',
                        appendTo: bannerRef.current,
                    });
                }
            };
            if (bannerRef.current) {
                bannerRef.current.appendChild(script);
            }
        }
    }, [isMobile, location.pathname]);

    if (isMobile || location.pathname === '/banner') {
        return null;
    }

    return (
        <div ref={bannerRef} style={{ position: 'fixed', top: '20%', left: '30px', zIndex: '1000' }}>
            {/* 광고가 스크립트를 통해 렌더링될 것입니다 */}
        </div>
    );
};

export default CoupangBanner;
