import { PropTypes } from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

function stripHtmlTags(html) {
    html = html.replace(/(<([^>]+)>)/gi, '');
    html = html.replace(/“|”/g, '');
    return html;
}

function PageTitle({ data }) {
    useEffect(() => {}, []);

    return (
        <Helmet>
            <>
                <title>{data[0].title}</title>
                <meta name="description" content={data[0].descript}></meta>
                <meta name="keywords" content={data[0].keyword}></meta>
                <link rel="canonical" href={data[0].url}></link>
                <meta property="og:title" content={data[0].title} />
                <meta property="og:image" content={data[0].img} />
                <meta property="og:description" content={data[0].descript} />
                <meta property="og:keyword" content={data[0].keyword} />
                <meta property="og:url" content={data[0].url} />
            </>
        </Helmet>
    );
}

PageTitle.prototype = {
    title: PropTypes.string.isRequired,
};
export default PageTitle;
