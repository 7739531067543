import { useMediaQuery } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const CoupangBanner_553485 = () => {
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width: 600px)');
    const bannerRef = useRef(null);

    useEffect(() => {
        const oldDocumentWrite = document.write;

        // document.write를 일시적으로 재정의
        document.write = function (content) {
            if (bannerRef.current) {
                bannerRef.current.innerHTML += content;
            }
        };

        const insertScript = (src, callback) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = false;
            script.onload = callback;
            if (bannerRef.current) {
                bannerRef.current.appendChild(script);
            }
        };

        if (bannerRef.current) {
            insertScript('https://ads-partners.coupang.com/g.js', () => {
                insertScript(
                    'https://adapi.inlcorp.com/cou/js.php?code=sportsseoul320x50&flt=0&width=320&height=50&adid=&click_id=',
                    () => {
                        setTimeout(() => {
                            document.write = oldDocumentWrite;
                        }, 100);
                    }
                );
            });
        }

        return () => {
            document.write = oldDocumentWrite;
        };
    }, [isMobile, location.pathname]);

    return (
        <div
            ref={bannerRef}
            style={{ position: 'relative', top: '0', left: '0', zIndex: '1000', display: 'inline-block' }}
        ></div>
    );
};

export default CoupangBanner_553485;
