import React from 'react';
import { Outlet } from 'react-router-dom';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import { Container } from '@mui/material';

const DefaultLayout = () => (
    <Container fixed style={{ padding: '0px' }}>
        <div style={{ position: 'sticky', top: '0', zIndex: '999' }}>
            <AppHeader />
        </div>
        <Outlet />
        <AppFooter />
    </Container>
);

export default DefaultLayout;
