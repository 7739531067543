import React, { useEffect, useState } from 'react';
import { GoogleAnalytics } from '../components/TrackingCode';
import { Helmet } from 'react-helmet-async';

import { Box, ImageList, useMediaQuery } from '@mui/material';
import { CustomAxios } from '../components/CustomAxios';

import CategoryCardText from '../components/CategoryCardText';
import { useLocation } from 'react-router-dom';
import CoupangBanner3 from '../components/CoupangBanner3';
import CoupangBanner_779752 from '../components/CoupangBanner_779752';
import CoupangBanner_779968 from '../components/CoupangBanner_779968';
import CoupangBanner_553485 from '../components/CoupangBanner_553485';
import CategoryCard_noimage from '../components/CategoryCard_noimage';

function BannerMedia() {
    const [cnt, setCnt] = useState({});
    const [isitemData, setItemData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const isLocation = useLocation();
    const searchParams = new URLSearchParams(isLocation.search);
    const cateParam = searchParams.get('skh');
    const media = searchParams.get('s');
    const isMobile = useMediaQuery('(max-width: 600px)');
    const numCols = isMobile ? 2 : 4;

    const renderInputs = (param) => {
        if (!param) return null;
        return param
            .split(',')
            .map((id, index) => <input key={`${index}`} id={`${id}`} type="hidden" value={cnt[id] || ''} />);
    };

    const CallData = async () => {
        await CustomAxios.post('/listsbe', {
            page: 1,
            category: 100100,
            search: cateParam,
            mpage: media,
        })
            .then((res) => {
                const newCntValues = {};
                const ids = cateParam.split(',');

                res.data.total.forEach((item) => {
                    if (ids.includes(item.id.toString())) {
                        newCntValues[item.id] = item.key;
                    }
                });

                setCnt(newCntValues);
                setItemData([...isitemData, ...res.data.datas]);
                setLoading(false);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        CallData();
    }, []);

    return (
        <Box>
            <Helmet>
                <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
            </Helmet>
            <GoogleAnalytics />
            <Box>
                {renderInputs(cateParam)}

                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box style={{ maxHeight: '300px' }}>
                        {cateParam
                            .split(',')
                            .map((_id) => (_id === '553485' ? <CoupangBanner_553485 key={_id} /> : null))}
                        {cateParam.split(',').map((_id) => (_id === '753485' ? <CoupangBanner3 key={_id} /> : null))}
                    </Box>

                    <Box style={{ maxWidth: '1200px' }}>
                        <ImageList variant="woven" cols={numCols} gap={15}>
                            {isitemData.length > 0
                                ? isitemData.map((item, index) => (
                                      <CategoryCard_noimage key={index} item={item} isLoading={isLoading} />
                                  ))
                                : ''}
                        </ImageList>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default BannerMedia;
