import { Box, ImageList, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CategoryCard from '../components/CategoryCard';
import PageTitle from '../components/PageTitle';
import { useLocation } from 'react-router-dom';
import { CustomAxios } from '../components/CustomAxios';
import CoupangBanner from '../components/CoupangBanner';
import { Helmet } from 'react-helmet-async';

const Detail = ({ match }) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const numCols = isMobile ? 2 : 4;
    const numPadding = isMobile ? '20px 20px' : '20px 60px';
    //const numMobileCss = isMobile ? 'content_recommend_box_imge_mobile' : 'content_recommend_box_imge';
    const isLocation = useLocation();
    const num = useLocation().pathname.split('/').pop();
    const searchParams = new URLSearchParams(isLocation.search);
    const cateParam = searchParams.get('cate');
    const [isLoading, setLoading] = useState(true);
    const [isitemData, setItemData] = useState([]);
    const [isData, setData] = useState([]);
    const [isHeader, setHeader] = useState([]);

    const handleShareClick = () => {
        const textarea = document.getElementById('copyTextarea');
        const url = `https://tlk.kr${isLocation.pathname}${isLocation.search}`;
        textarea.value = url;
        textarea.select();
        document.execCommand('copy');
    };

    const handleScrollToTopClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const fetchContent = async (_cate) => {
        setLoading(true);

        await CustomAxios.post('/content', {
            num: num,
            category: _cate,
        })
            .then((res) => {
                let _data = res.data.datas[0];
                setData(res.data.datas[0]);
                setItemData(res.data.lists);
                setHeader([
                    {
                        title: _data.title,
                        img: _data.img,
                        keyword: _data.keyword,
                        descript: _data.descript,
                        url: `https://tlk.kr${isLocation.pathname}${isLocation.search}`,
                    },
                ]);
            })
            .catch((err) => {});

        setLoading(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        const handlePageChange = () => {
            const scrollY = window.scrollY;
            window.history.pushState({ scrollY }, document.title);
        };

        const handlePopstate = (event) => {
            if (event.state && event.state.scrollY) {
                window.scrollTo({ top: event.state.scrollY, behavior: 'smooth' });
            }
        };

        window.addEventListener('beforeunload', handlePageChange);
        window.addEventListener('popstate', handlePopstate);

        fetchContent(cateParam);

        return () => {
            window.removeEventListener('beforeunload', handlePageChange);
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [isLocation]);

    return (
        <Box>
            <Helmet>
                <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
                />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css"
                />
                <link href="https://fonts.googleapis.com/css2?family=Ysabeau+SC&display=swap" rel="stylesheet" />
            </Helmet>

            {isLoading ? null : <PageTitle data={isHeader} />}
            <Box
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '300px',
                    backgroundImage: "url('/1563298723dcfebaa392e3.jpg')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    overflow: 'hidden',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        fontSize: '24px',
                        padding: '20px',
                        width: '100%',
                        height: '100%',
                        backdropFilter: 'blur(4px)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ fontSize: '1rem', padding: '20px' }}>
                        <h1 style={{ marginBottom: '0' }}>{isData.title}</h1>
                        <Box>
                            <p style={{ marginTop: '0', textAlign: 'right' }}>{isData.updatedate}</p>
                        </Box>
                    </div>
                </div>
            </Box>
            <div style={{ left: 'calc(50% + 40rem)', position: 'fixed', zIndex: '100', top: '65%' }}>
                <div
                    style={{
                        border: '1px solid #efefef',
                        padding: '35px',
                        display: 'grid',
                        fontSize: '0.75rem',
                        textAlign: 'center',
                        cursor: 'pointer',
                        borderRadius: '10px',
                    }}
                    onClick={handleShareClick}
                >
                    <span>
                        <svg
                            width="1.8rem"
                            height="1.8rem"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                xmlns="http://www.w3.org/2000/svg"
                                d="M12 2L12.3448 1.63793L12 1.30952L11.6552 1.63793L12 2ZM11.5 2V14H12.5V2H11.5ZM11.6552 2.36207L15.6552 6.17159L16.3448 5.44746L12.3448 1.63793L11.6552 2.36207ZM11.6552 1.63793L7.65517 5.44746L8.34483 6.17159L12.3448 2.36207L11.6552 1.63793Z"
                                fill="#1A1A1A"
                            ></path>
                            <path
                                xmlns="http://www.w3.org/2000/svg"
                                d="M9 9H5C4.44772 9 4 9.44772 4 10V21C4 21.5523 4.44772 22 5 22H19C19.5523 22 20 21.5523 20 21V10C20 9.44772 19.5523 9 19 9H15"
                                stroke="#1A1A1A"
                            ></path>
                        </svg>
                    </span>
                    <span>공유</span>
                    <span style={{ rotate: '90deg', marginTop: '30px' }} onClick={handleScrollToTopClick}>
                        <svg
                            width="1.3rem"
                            height="1.3rem"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M2 12H22M2 12L12 2M2 12L12 22" stroke="#1A1A1A"></path>
                        </svg>
                    </span>
                    <span>위로</span>

                    <textarea
                        id="copyTextarea"
                        style={{
                            position: 'fixed',
                            top: '-9999px',
                            left: '-9999px',
                        }}
                    ></textarea>
                </div>
            </div>

            <div
                className="content_box"
                style={{
                    borderBottom: '1px solid #efefef',
                    padding: numPadding,
                    fontSize: '1rem',
                    fontFamily: 'Pretendard',
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: isData.content }}></div>
                <div className="commentbox">
                    쿠팡파트너스 활동의 일환으로, 일정액의 수수료를 제공받을 수 있지만 구매자에게 추가 비용이 발생하지는
                    않습니다.
                </div>
            </div>
            {isitemData.length > 0 ? (
                <div style={{ borderBottom: '1px solid #efefef', padding: '20px' }}>
                    <div>
                        <p style={{ fontFamily: 'Pretendard', fontSize: '1.2rem', fontWeight: '600' }}>
                            다른 컨텐츠를 확인해보세요.
                        </p>
                    </div>
                    <ImageList variant="woven" cols={numCols} gap={15}>
                        {isitemData.map((item, index) => (
                            <CategoryCard key={index} item={item} isLoading={isLoading} />
                        ))}
                    </ImageList>
                </div>
            ) : null}

            <Box>
                <CoupangBanner />
            </Box>
        </Box>
    );
};

export default Detail;
