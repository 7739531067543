import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './view/Home';
import Detail from './view/Detail';
import Search from './view/Search';
import Banner from './view/Banner';
import DefaultLayout from './components/DefaultLayout';
import BannerLayout from './components/BannerLayout';
import BannerMedia from './view/BannerMedia';

const MyRoute = () => {
    return (
        <Fragment>
            <Routes>
                <Route element={<DefaultLayout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/content/:seq" element={<Detail />} />
                    <Route path="/search" element={<Search />} />
                </Route>

                <Route element={<BannerLayout />}>
                    <Route path="/banner" element={<Banner />} />
                    <Route path="/contents" element={<BannerMedia />} />
                </Route>
            </Routes>
        </Fragment>
    );
};

export default MyRoute;
