import { useEffect } from 'react';

const GoogleAnalytics = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-P4TTZ574H2`;
        document.head.appendChild(script);

        const script2 = document.createElement('script');
        script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-P4TTZ574H2');
    `;
        document.head.appendChild(script2);

        return () => {
            document.head.removeChild(script);
            document.head.removeChild(script2);
        };
    }, []);

    return null;
};

const NaverAnalytics = () => {
    useEffect(() => {
        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = 'https//wcs.naver.net/wcslog.js';
        document.head.appendChild(script1);

        const script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.innerHTML = `
      if(!wcs_add) var wcs_add = {};
      wcs_add["wa"] = "3a1ca4f8b2e8e";
      if(window.wcs) {
        wcs_do();
      }
    `;
        document.head.appendChild(script2);

        return () => {
            document.head.removeChild(script1);
            document.head.removeChild(script2);
        };
    }, []);

    return null;
};

export { GoogleAnalytics, NaverAnalytics };
