import React from 'react';
import { Grid } from '@mui/material';

const AppFooter = () => {
    return (
        <footer
            style={{
                backgroundColor: '#f9f9f9',
                color: 'black',
                padding: '10px',
                marginTop: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Pretendard',
                fontSize: '0.875rem',
            }}
        >
            <ul style={{ padding: '0' }}>
                <li style={{ display: 'flex' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <p style={{ fontWeight: 'bold', margin: '8px' }}>상호명</p>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={{ margin: '8px' }}>paln11</p>
                        </Grid>
                    </Grid>
                </li>
                <li style={{ display: 'flex' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <p style={{ fontWeight: 'bold', margin: '8px' }}>의견.문의</p>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={{ margin: '8px' }}>seougi521@gmail.com</p>
                        </Grid>
                    </Grid>
                </li>
            </ul>
            {/*<Container>*/}
            {/*<Typography variant="body1">© {new Date().getFullYear()} My Website. All rights reserved.</Typography>*/}
            {/*</Container>*/}
        </footer>
    );
};

export default AppFooter;
