import React, { useEffect, useState } from 'react';
import { GoogleAnalytics } from '../components/TrackingCode';
import { Helmet } from 'react-helmet-async';
import CoupangBanner3 from '../components/CoupangBanner3';
import { Box } from '@mui/material';
import { CustomAxios } from '../components/CustomAxios';

import CategoryCardText from '../components/CategoryCardText';

function Banner() {
    const [page, setPage] = useState(1);
    const [cnt, setCnt] = useState(1);
    const [pageCate, setPageCate] = useState(100100);
    const [isitemData, setItemData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const CallData = async () => {
        await CustomAxios.post('/listsb', {
            page: page,
            category: pageCate,
            search: '',
        })
            .then((res) => {
                console.log(res.data.total);
                setItemData([...isitemData, ...res.data.datas]);
                setCnt(res.data.total);
                setLoading(false);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        CallData();
    }, []);

    return (
        <Box>
            <Helmet>
                <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
            </Helmet>
            <GoogleAnalytics />
            <Box>
                <input id="form" type="hidden" value={cnt} />

                <Box>
                    <CoupangBanner3 />
                </Box>
                <Box>
                    {isitemData.length > 0
                        ? isitemData.map((item, index) => (
                              <CategoryCardText key={index} item={item} isLoading={isLoading} />
                          ))
                        : null}
                </Box>
            </Box>
        </Box>
    );
}

export default Banner;
