import { AppBar, IconButton, InputBase, Paper, Toolbar } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { metaData } from './Datas';

const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between',
});

const Search = styled('div')(({ theme }) => ({ backgroundColor: 'white', padding: '0 10px', borderRadius: '5px' }));

const AppHeader = () => {
    const [searchTerm, setSearchTerm] = useState('');
    let navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate(`/search?search=${searchTerm}`);
    };

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <AppBar
            position="sticky"
            style={{ backgroundColor: '#fff', color: 'black', boxShadow: 'none', borderBottom: '1px solid #efefef' }}
        >
            <StyledToolbar>
                <a href="https://tlk.kr" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/*<img src={`${metaData[0].img}`} style={{ width: '35px', height: '35px', marginRight: '10px' }} alt="logo" />*/}
                    <p style={{ fontSize: '1.5rem', fontWeight: '600', fontFamily: 'Ysabeau SC', color: '#333' }}>
                        ItemChoice
                    </p>
                </a>

                <Search>
                    <form onSubmit={handleSubmit}>
                        <Paper
                            elevation={1}
                            style={{
                                padding: '0 4px 0 20px',
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#f9f9f9',
                                boxShadow: 'none',
                                border: '1px solid #efefef',
                            }}
                        >
                            <InputBase
                                style={{ marginLeft: 8, flex: 1, fontSize: '0.75rem', fontFamily: 'Pretendard' }}
                                placeholder="검색어를 입력해주세요."
                                value={searchTerm}
                                onChange={handleChange}
                            />
                            <IconButton type="submit" style={{ padding: 2 }} aria-label="Search">
                                <SearchIcon fontSize="small" />
                            </IconButton>
                        </Paper>
                    </form>
                </Search>
            </StyledToolbar>
        </AppBar>
    );
};

export default AppHeader;
