import { ImageListItem, Skeleton } from '@mui/material';
import React from 'react';

const CategoryCardSkeleton = ({ item }) => {
    return (
        <div style={{ overflow: 'hidden', flexShrink: 0 }}>
            <div style={{ display: 'block' }}>
                <ImageListItem>
                    <Skeleton variant="rectangular" width={300} height={200} />

                    <div style={{ lineHeight: '1.3' }}>
                        <Skeleton height={30} width={200} style={{ marginBottom: '5px', marginTop: '8px' }} />
                        <Skeleton height={20} width={150} />
                    </div>
                </ImageListItem>
            </div>
        </div>
    );
};

export default CategoryCardSkeleton;
